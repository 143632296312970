import React from "react";
import { Link, graphql } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";

import theme from "../../theme/theme";
import { type } from "../../theme/typography";
import Color from "color";

import Layout from "../../components/layout";
import Container from "../../components/container";
import SEO from "../../components/seo";

const colors = {
  backgroundColor: "hsl(200, 20%, 98%)",
  bodyColor: "hsl(231, 38%, 22%)",
  footerColor: "hsl(246, 8%, 48%)"
};

const CaseWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -${theme.spacing.small};
  padding-top: ${theme.spacing.small};
  ${theme.breakpoint.down("small")`
    padding-top: ${theme.spacing.xxsmall};
  `}
`;

const Case = styled.div`
  flex: 0 1 50%;
  padding: ${theme.spacing.small};
  ${theme.breakpoint.down("medium")`
    flex: 1 1 100%;
  `};
  h2 {
    ${type("header2")};
    color: ${colors.bodyColor};
    margin: ${theme.spacing.small} 0 ${theme.spacing.xxsmall} 0;
    max-width: ${theme.variables.siteMeasure}px;
  }
  p {
    margin: ${theme.spacing.xsmall} 0 0 0;

    ${type("bodySmall")};
    color: ${Color(colors.bodyColor)
      .fade(0.2)
      .string()};
    max-width: ${theme.variables.siteMeasure}px;
  }
`;

const ProjectName = styled.strong`
  color: ${colors.bodyColor};
  ${type("body")};
  font-weight: 500;
`;

const Thumbnail = styled(Img)`
  min-height: 200px;
  max-height: 260px;
`;

export default ({ data }) => (
  <React.Fragment>
    <SEO
      title="Work"
      description="Selected product design work and projects."
    />
    <Layout
      backgroundColor={colors.backgroundColor}
      bodyColor={colors.bodyColor}
      footerColor={colors.footerColor}
      navColor={colors.bodyColor}
    >
      <Container>
        <CaseWrapper>
          {data.allMarkdownRemark.edges
            .filter(({ node }) => node.frontmatter.template === "work")
            .map(({ node }) => (
              <Case key={node.id}>
                <Link to={node.fields.slug}>
                  <Thumbnail
                    fluid={node.frontmatter.headerImage.childImageSharp.fluid}
                    alt={"Thumbnail image for " + node.frontmatter.project}
                  />
                  {/* <h2>{node.frontmatter.title}</h2> */}
                  <p>
                    <ProjectName>{node.frontmatter.project}</ProjectName>
                    {" / "}
                    {node.frontmatter.introShort}
                  </p>
                </Link>
              </Case>
            ))}
        </CaseWrapper>
      </Container>
    </Layout>
  </React.Fragment>
);

//       filter: { frontmatter: { publish: { eq: "true" } } }

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { publish: { eq: "true" } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            project
            template
            title
            introShort
            headerImage {
              childImageSharp {
                fluid(maxWidth: 760) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;
